/**
 * Helper to parse acceleration/deceleration from a string like "1.3 m/s²" or "-2.4 m/s²".
 */
function parseAcceleration(text: string): number {
	return parseFloat(text.replace(/[^\d.\-]/g, ""));
  }
  
  /**
   * Helper function that filters the top values out of a trace
   *
   * @param trace    One "trace" object that has `lon`, `lat`, `text` arrays.
   * @param topN     Maximum number of points to keep.
   * @param parseFn  Function to parse the "text" into a numeric value (like parseAcceleration).
   * @param sortAbs  If true, sort by absolute value descending; if false, sort by normal descending.
   */
  function filterTracePoints(
	trace: any,
	topN: number,
	parseFn: (txt: string) => number,
	sortAbs: boolean
  ): any {
	// 1) Combine all trace information into one array
	const combined = trace.lon.map((lonVal: number, i: number) => ({
	  lon: lonVal,
	  lat: trace.lat[i],
	  text: trace.text[i],
	  value: parseFn(trace.text[i]),
	}));
  
	// 2) Sort the array based on the value
	combined.sort((a: any, b: any) => {
	  if (sortAbs) {
		// (for deceleration)
		return Math.abs(b.value) - Math.abs(a.value);
	  } else {
		// (for acceleration)
		return b.value - a.value;
	  }
	});
  
	// 3) Take the top N values
	const topPoints = combined.slice(0, topN);
  
	// 4) Return data in original trace format
	return {
	  ...trace,
	  lon: topPoints.map((p: any) => p.lon),
	  lat: topPoints.map((p: any) => p.lat),
	  text: topPoints.map((p: any) => p.text),
	};
  }
  
  /**
   * Generates a *new* “filtered trace” based on the name, without modifying the original.
   */
  function createFilteredTrace(trace: any): any | null {
	switch (trace.name) {
	  case "Starkes Beschleunigen": {
		const filtered = filterTracePoints(trace, 40, parseAcceleration, false);
		return { ...filtered, name: trace.name + " (Top Events)" };
	  }
  
	  case "Starkes Bremsen": {
		const filtered = filterTracePoints(trace, 15, parseAcceleration, true);
		return { ...filtered, name: trace.name + " (Top Events)" };
	  }
  
	  default:
		return null;
	}
  }
  
  function filterData(data: any): any {
	if (!data || !Array.isArray(data.traces)) {
	  throw new Error("Error: Filter data datatype");
	}
  
	const topEventTraces: any[] = [];
	const normalTraces: any[] = [];
	const hiddenTraces: any[] = [];
  
	data.traces.forEach((trace: any) => {
	  const isAccelOrBrake = 
		trace.name === "Starkes Beschleunigen" || trace.name === "Starkes Bremsen";
  
	  const filteredTrace = createFilteredTrace(trace);
	  if (filteredTrace) {
		topEventTraces.push(filteredTrace);
	  }
  
	
	  let renamedTraceName = trace.name;
	  if (trace.name === "Starkes Beschleunigen") {
		renamedTraceName = "Starkes Beschleunigen (Alle Events)";
	  } else if (trace.name === "Starkes Bremsen") {
		renamedTraceName = "Starkes Bremsen (Alle Events)";
	  }
  
	  if (isAccelOrBrake) {
		hiddenTraces.push({
		  ...trace,
		  name: renamedTraceName,
		  visible: "legendonly"
		});
	  } else {
		// Energy
		normalTraces.push(trace);
	  }
	});
  
	const newTraces = [
	  ...normalTraces,
	  ...topEventTraces,
	  ...hiddenTraces
	];
  
	return {
	  ...data,
	  traces: newTraces,
	};
  }

export default filterData;